import { qspost } from '@/config/axios';

//获取数据
export const getList = data => qspost("request?", data, "getEditionType");

//新增数据
export const add = data => qspost("request?", data, "addEditionType");

//修改状态
export const freezeThaw = data => qspost("request?", data, "lockEditionType");

//编辑数据
export const edit = data => qspost("request?", data, "updateEditionType");

//删除数据
export const del = data => qspost("request?", data, "delEditionType");

//读取可用分类
export const getTypeList = data => qspost("request?", data, "getVideoTypeList");