<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true,dialogArr.type_id=0,title = '添加分类'">添加分类</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="上级分类" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{getTypeName(scope.row.type_id)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="分类名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.status == '启用' ? '#33C933' : '#F33333' }">{{scope.row.status}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="freezeThaw(scope.row,scope.row.status == '启用' ? '禁用' : '启用')">{{scope.row.status == '启用' ? '禁用' : '启用'}}</span>
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加修改 -->
    <el-dialog :title="title" v-model="dialogAdd" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">分类名称：</div>
        <el-input v-model="dialogArr.title" placeholder="分类名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">上级分类：</div>
        <el-select v-model="dialogArr.type_id">
          <el-option value="0" label="请选择">请选择</el-option>
          <el-option v-for="item in typeList" :key="item" :value="item.Value" :label="item.Label">{{ item.Label }}</el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">权重：</div>
        <el-input v-model="dialogArr.weight" placeholder="权重" size="small" type="number"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加分类'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList, add, freezeThaw, edit, del,getTypeList } from "@/api/video/editionType";
export default {
  name: "videoClass",
  components: {
  },
  data() {
    return {
      tableData: [{}], //数据
      typeList:[],
      row: [], //选中的数据
      title: "", //弹窗标题
      dialogAdd: false, //添加弹窗
      dialogArr: { title: "", weight: 0,type_id:0 }, //添加数组
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getTypeList();
    this.getList();
  },
  methods: {
    getTypeName(id){
      if (id === undefined || id == 0){
        return ""
      }
      for(var i in this.typeList){
        if (this.typeList[i].Value == id){
          return this.typeList[i].Label
        }
      }
      return "未知ID:"+id
    },
    //获取数据
      getTypeList(){
        getTypeList([]).then((res)=>{
          if(res.code == 0){
            this.typeList = res.data
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        },(err)=>{
          console.log(err)
        })
      },
    getList(){
      getList([
      ]).then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加数据
    add() {
      if(this.dialogArr.weight < 0) return this.$message({ message: "权重不能未负数", type: "error" });
      add([{ key:"title", val: this.dialogArr.title },{ key: "weight", val: this.dialogArr.weight },{ key: "type_id", val: this.dialogArr.type_id }
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.data, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //把数据放入编辑内
    editor(row) {
      this.row = row;
      this.title = `编辑分类(ID：${row.role_id})`;
      this.dialogArr = { title: row.title, weight: row.weight ,type_id: row.type_id};
      this.dialogAdd = true;
    },
    //编辑数据
    edit() {
      if(this.dialogArr.weight < 0) return this.$message({ message: "权重不能未负数", type: "error" });
      edit([
        { key:"id", val: this.row.id },
        { key:"title", val: this.dialogArr.title },
        { key: "weight", val: this.dialogArr.weight },
        { key: "type_id", val: this.dialogArr.type_id }
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.data, type: "success" });
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //状态修改
    freezeThaw(row,str) {
      this.row = row;
      this.$confirm(
        "你确定要" + str + "视频分类：" + row.title + "吗？",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          freezeThaw([
            { key:"id", val: this.row.id }
          ])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除数据
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除视频分类：" + row.title + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key:"id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭弹窗清空数据
    closeDialog() {
      this.dialogArr = { title: "", weight: 0 };
      this.row = [];
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>